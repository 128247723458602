import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`Because of the 4-6″ overnight snow forcast the 6am class will be
cancelled.  We plan on opening for Open gym by 8:00am.  All other
classes are on as scheduled.`}</em></strong></p>
    <p>{`Split Jerk 4-4-4-4 to a 4RM`}</p>
    <p>{`Band Pull Aparts 10-10-10-10`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`9-S2OH (135/95)`}</p>
    <p>{`15-Burpees`}</p>
    <p>{`21-Calorie Row`}</p>
    <p><em parentName="p">{`*`}{`compare to 2/5/17`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      